export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Swag Sports',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    'Get ready to elevate your game, sharpen your skills, and unleash your full potential on the field! Now, lets Swing With A Grudge!',
  'client_theme.ThemeSidebar.learn_more': 'Learn More', // leave a space here so we don't display this for main instance.
};
