export default {
  // The keys defined here will override those in the base locale file, for example:
  // fontFamily: 'Courier New'
  name: 'Swag Sports',
  siteURL: 'http://www.swagsports.net',
  logo: {
    url: 'https://s3.amazonaws.com/upperhand-app/static/images/swagsports_logo.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#03aeef',
    mobileSignInColor: '#03aeef',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#03aeef',
    accent1Color: '#000',

    primary: {
      main: '#03aeef',
    },
    secondary: {
      main: '#000',
    },
  },
  sideNav: {
    selectedColor: '#03aeef',
  },
  icons: {
    cart: '#03aeef',
  },
  statusBar: {
    backgroundColor: '#03aeef',
  },
};
